























import { computed } from '@vue/composition-api';

export default {
  props: {
    minimumBudget: {
      type: String,
      default: '$0'
    },
    maximumBudget: {
      type: String,
      default: '$5,000'
    }
  },
  setup(props, ctx) {
    const minimumBudgetVal = computed({
      get: () => props.minimumBudget,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const maximumBudgetVal = computed({
      get: () => props.maximumBudget,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const minBudgetList = [
      '$0',
      '$250',
      '$500',
      '$750',
      '$1,000',
      '$1,500',
      '$2,000',
      '$2,500',
      '$3,000',
      '$3,500',
      '$4,000',
      '$4,500',
      '$5,000',
      '$6,000',
      '$7,000',
      '$8,000',
      '$9,000',
      '$10,000'
    ];
    const maxBudgetList = [
      '$10,000',
      '$9,500',
      '$9,000',
      '$8,500',
      '$8,000',
      '$7,500',
      '$7,000',
      '$6,500',
      '$6,000',
      '$5,500',
      '$5,000',
      '$4,500',
      '$4,000',
      '$3,500',
      '$3,000',
      '$2,500',
      '$2,000',
      '$1,500',
      '$1,000',
      '$500',
      '$0'
    ];
    return { minimumBudgetVal, maximumBudgetVal, minBudgetList, maxBudgetList };
  }
};
